.bg-dorsia {
  background-color: #800;
  background-image: url("../../img/dorsia_entrance.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

.nrg_sel {
  position: absolute;
  padding: 5px;
  min-width: 300px;
  color: #000000;
  background: #fff;
  border-radius: 4px;
  border: 1px #ccc solid;
  box-shadow: 0 7px 8px -4px rgba(0,0,0,.2), 0 13px 19px 2px rgba(0,0,0,.14), 0 5px 24px 4px rgba(0,0,0,.12);
  z-index: 29999;
}
