
.pos_bgx {
    background: linear-gradient(
        to right, 
        rgb(5, 117, 230), 
        rgb(2, 27, 121)
    );
    background: radial-gradient(
        circle at 85.4% 50.8%,
        rgb(43, 136, 223) 0%,
        rgb(70, 132, 230) 74.2%
    );
    color: #fff;
}
.pos_bg {
    background: #4b6cb7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #eee;
}
.pos_body {
    padding: 5px 15px;
    background: linear-gradient(
        to right, 
        rgb(255, 255, 255), 
        rgb(245, 245, 245)
    );
    border-radius: 35px 0px 0px 0px;
    overflow: hidden;
    color: #222;
}
.pos_left {
    padding: 5px;
    width: 250px;
    overflow: auto;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.logo_top {
    width: 125px;
    color: #eee;
}
.pos_box {
    padding: 5px;
    min-width: 250px;
    border: 1px solid #ccc;
    border-radius: 12px;
    overflow: hidden;
    color: #222;
}
.pos_left::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

input[type=text], input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    margin-top: 2px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.loginbtn {
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

button:hover {
    opacity: 0.8;
}

.cancelbtn {
    width: auto;
    background-color: #f44336;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
}

.imgcontainer {
    text-align: center;
    margin: 24px 0 12px 0;
}

img.avatar {
    width: 40%;
    border-radius: 50%;
}
img.logo {
    width: 40%;
}
img.logotop {
    width: 120px;
    object-fit: contain;
}

.container {
    padding: 16px;
}

.w80 {
    width: 80%;
}

span.psw {
    float: right;
    padding-top: 16px;
}
.txtdesc {
    font-size: 16px;
    text-align: center;
}
.psw {
    font-size: 12px;
    text-align: center;
}
label {
    font-size: 16px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.ccdetails {
    display: flex;
    flex-direction: row;
}
.ccdone {
    background: #008800;
    color: #fff;
}
.hide {
    display: none;
}
.txt12 {
    font-size: 12px;
}

.grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto auto auto;
  }
  .grid > * {
    padding: 5px;
    min-height: 125px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .grid > *:hover {
    border: 1px solid #ff9900;
  }
  
/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
    span.psw {
        display: block;
        float: none;
    }
    .cancelbtn {
        width: 100%;
    }
    .ccdetails {
        display: flex;
        flex-direction: column;
    }
}

.login_bg {
    background: #f8f9fd;
}
.login-icon {
    width: 80px;
    height: 80px;
    background: #1089ff;
    border-radius: 50%;
    font-size: 30px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.cbbox {
    margin: 0px auto;
    padding: 20px 5px;
    width: 100%;
    max-width: 500px;
    background: #fff;
    color: #222;
    font-size: 22px;
    border-radius: 10px;
    border: 1px #222 solid;

    -webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
}
.cbtitle {
    margin: 0px auto;
    padding: 10px 5px;
    background-color: #fff;
    color: #222;
    font-size: 22px;
}
.cbcardnr {
    width: 300px;
    padding: 3px 5px;
    height: 35px;
    line-height: 35px;
}
.cbcardnr {
    width: 300px;
    padding: 3px 5px;
    height: 35px;
    line-height: 35px;
}
.cbexp {
    width: 145px;
    padding: 3px 5px;
    height: 35px;
    line-height: 35px;
}
.cbbtn {
    padding: 5px 5px;
    width: 100%;
    height: 75px;
    line-height: 35px;
    border: 1px solid #555;
    border-radius: 5px;
    background-color: #ececec;
    color: #555;
    text-align: center;
}
.cbbtn:hover {
    background-color: #fff;
    color: #ff9900;
}
.cbcenter {
    text-align: center;
}
.cbrow {
    padding: 3px 5px;
    width: 100%;
}
