.btn:focus {
  outline: none;
}

.cdi_f01 {
  border: 0px solid #ccc;
}
.cdi_f01 .btn {
  width: 250px;
  height:50px;
}
.cdi_f01 .form-group {
    margin-bottom: 15px;
}
.cdi_f01 .form-group .header {
  font-size: 18px;
  height:50px;
  line-height:50px;
  text-align: right;
  padding-right: 5px;
}
.cdi_f01 .form-group .form-control {
  font-size: 18px;
  height:50px;
}

.cdi_f02 {
  border: 0px solid #ccc;
  padding: 0px;
}
.cdi_f02 .btn {
  width: 250px;
  height:40px;
}
.cdi_f02 .form-row {
    padding: 5px;
}
.cdi_f02 .form-sep {
  margin: 8px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f02 .form-sep-text {
  margin: 8px 0px 15px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f02 .form-group {
    margin-bottom: 0px;
}
.cdi_f02 .form-group .nheader {
  font-size: 14px;
  min-width: 100px;
  height:40px;
  line-height:40px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f02 .form-group .header {
  font-size: 14px;
  min-width: 175px;
  height:40px;
  line-height:40px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f02 .form-group .topheader {
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}
.cdi_f02 .form-group .form-control {
  font-size: 18px;
  height:40px;
}

.cdi_f03 {
  border: 0px solid #ccc;
  padding: 0px 5px;
}
.cdi_f03 .btn {
  width: 250px;
  height:40px;
}
.cdi_f03 .form-row {
    padding: 2px;
}
.cdi_f03 .form-sep {
  margin: 8px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f03 .form-sep-text {
  margin: 8px 0px 15px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f03 .form-group {
    margin-bottom: 0px;
}
.cdi_f03 .form-group .nheader {
  font-size: 14px;
  min-width: 100px;
  height:30px;
  line-height:30px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f03 .form-group .header {
  font-size: 14px;
  min-width: 175px;
  height:30px;
  line-height:30px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f03 .form-group .topheader {
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}
.cdi_f03 .form-group .form-control {
  font-size: 16px;
  height:40px;
}

.cdi_f04 {
  border: 0px solid #ccc;
}
.cdi_f04 .btn {
  width: 150px;
  height:35px;
}
.cdi_f04 .w200 {
  width: 200px;
  height:35px;
}
.cdi_f04 .w300 {
  width: 300px;
  height:35px;
}
.cdi_f04 .form-group {
    margin-bottom: 10px;
}
.cdi_f04 .form-group .header {
  font-size: 16px;
  height:35px;
  line-height:35px;
  text-align: right;
  padding-right: 5px;
}
.cdi_f04 .form-group .form-control {
  font-size: 16px;
  height:35px;
}

.cdi_f05 {
  border: 1px solid #ccc;
}
.cdi_f05 .btn {
  width: 150px;
  height:30px;
}
.cdi_f05 .form-group {
    margin-bottom: 5px;
}
.cdi_f05 .form-group .header {
  font-size: 14px;
  height:30px;
  line-height:30px;
  text-align: right;
  padding-right: 5px;
}
.cdi_f05 .form-group .form-control {
  font-size: 14px;
  height:30px;
}

.cdi_lt18 {
  border: 0px solid #ccc;
  padding: 5px 5px;
  color: #000;
}
.cdi_lt18 .header {
  font-size: 14px;
  text-align: left;
}
.cdi_lt18 .fdata {
  font-size: 18px;
  text-align: left;
}

.cdi_f35 {
  border: 0px solid #ccc;
  padding: 0px;
  color: #000;
}
.cdi_f35 .btn {
  width: 250px;
  height:35px;
}
.cdi_f35 .form-row {
    padding: 2px;
}
.cdi_f35 .form-group {
    padding: 2px;
}
.cdi_f35 .form-sep {
  margin: 8px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f35 .form-sep-text {
  margin: 8px 0px 15px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f35 .form-group {
    margin-bottom: 0px;
}
.cdi_f35 .form-group .nheader {
  font-size: 14px;
  min-width: 100px;
  height:35px;
  line-height:35px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f35 .form-group .header {
  font-size: 14px;
  min-width: 125px;
  height:35px;
  line-height:35px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f35 .form-group .fdata {
  font-size: 14px;
  height:35px;
  line-height:35px;
  text-align: left;
}
.cdi_f35 .form-group .topheader {
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}
.cdi_f35 .form-group .form-control {
  font-size: 16px;
  height:35px;
}
.form-content{
  background: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.cdi_ft40 {
  border: 1px solid #ccc;
}
.cdi_ft40 .btn {
  width: 200px;
  height:40px;
}
.cdi_ft40 .form-group {
    margin-bottom: 0px;
}
.cdi_ft40 .form-group .header {
  font-size: 12px;
  text-align: left;
  margin: 0px;
  padding: 0px;
  padding-left: 5px;
}
.cdi_ft40 .form-group .form-control {
  font-size: 14px;
  height:40px;
}

.cdi_f50 {
  border: 0px solid #ccc;
  padding: 0px;
  color: #000;
}
.cdi_f50 .btn {
  width: 250px;
  height:50px;
}
.cdi_f50 .form-row {
    padding: 2px;
}
.cdi_f50 .form-sep {
  margin: 8px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f50 .form-sep-text {
  margin: 8px 0px 15px 0px;
  padding: 0px;
  height:2px;
  background: #090;
  opacity: 0.8;

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.cdi_f50 .form-group {
    margin-bottom: 0px;
}
.cdi_f50 .form-group .nheader {
  font-size: 14px;
  min-width: 100px;
  height:50px;
  line-height:50px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f50 .form-group .header {
  font-size: 14px;
  min-width: 175px;
  height:50px;
  line-height:50px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.cdi_f50 .form-group .topheader {
  font-size: 12px;
  text-align: left;
  padding-left: 5px;
}
.cdi_f50 .form-group .form-control {
  font-size: 16px;
  height:50px;
}

.divider_orange {
	margin: 5px;
	padding: 0px;
	border-top: 1px solid #f99000;
}
.divider_blue {
	margin: 5px;
	padding: 0px;
	border-top: 1px solid #000088;
}
.divider_green {
	margin: 5px;
	padding: 0px;
	border-top: 1px solid #008800;
}
.divider_red {
	margin: 5px;
	padding: 0px;
	border-top: 1px solid #880000;
}
.divider_gray {
	margin: 5px;
	padding: 0px;
	border-top: 1px solid #888888;
}
.divy_orange {
	margin: 5px;
	padding: 0px;
	border-left: 1px solid #f99000;
}
.divy_blue {
	margin: 5px;
	padding: 0px;
	border-left: 1px solid #000088;
}
.divy_lightblue {
	margin: 5px;
	padding: 0px;
	border-left: 1px solid #90b6ff;
}
.divy_green {
	margin: 5px;
	padding: 0px;
	border-left: 1px solid #008800;
}
.divy_red {
	margin: 5px;
	padding: 0px;
	border-left: 1px solid #880000;
}
.divy_gray {
	margin: 5px;
	padding: 0px;
	border-left: 1px solid #888888;
}
